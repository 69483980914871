<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.type') }}
      </div>
      <el-select
        clearable
        size="mini"
        :value="model && toSnakeCase(model)"
        :placeholder="$t('task_editor.labels.field_type')"
        @input="changeType($event)"
      >
        <el-option
          v-for="item in Object.keys(taskTypes)"
          :key="item"
          :label="taskTypes[item]"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <component
      v-if="this.editor"
      :is="this.editor"
      :attributes="attributes"
      :extensions="extensions"
      @change-extension="$emit('change-extension', $event)"
      @remove-extension="$emit('remove-extension', $event)"
    ></component>
  </div>
</template>

<script>
import mixins from '../mixins'

const TASK_TYPES_MAPPER = {
  'CalculateCollection': 'BpmnEngine.Infrastructure.Extension.Registry.CalculateCollection',
  'ExecuteCommand': 'BpmnEngine.Infrastructure.Extension.Registry.ExecuteCommand'
}

export default {
  name: 'ServiceTaskEditor',
  mixins: [mixins],
  data () {
    return {
      model: null,
      taskTypes: this.$t('process_editor.element_properties.service_task.types')
    }
  },
  computed: {
    editor () {
      if (!this.model) {
        return null
      }
      let editor = this.camelCaseType

      return () => import(`./ServiceTaskTypes/${editor}`)
        .catch(() => {
          console.error(this.$t('process_editor.editor_not_found'), editor)
        })
    },
    camelCaseType () {
      if (!this.model) {
        return null
      }
      let answer = this.toCamelCase(this.model)
      return answer.charAt(0).toUpperCase() + answer.slice(1)
    }
  },
  mounted () {
    this.model = (this.attributes['extension:class'] || '').split('.').pop()
  },
  methods: {
    changeType (value) {
      this.model = value
      this.$emit('change-attribute', { name: 'extension:class', value: TASK_TYPES_MAPPER[this.camelCaseType] || this.camelCaseType })
    }
  }
}
</script>
